var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.fields.filter((el) => el.group_id == 1)),function(profile){return _c('b-row',{key:'profile-fields-' + profile.id,staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"4"}},[_c('p',{staticClass:"text-title-input"},[_vm._v(" "+_vm._s(profile.name)+" "),(profile.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()])]),_c('b-col',{attrs:{"set":(_vm.field = _vm.v.form[_vm.checkIsProfile(profile.field_profile_type_id)]),"id":'field-' + profile.profile_name}},[_vm._v(" "+_vm._s(profile.validate_text)+" "),(profile.field_type_id == 1)?_c('b-row',[_c('b-col',[(profile.field_type_id == 1)?_c('InputText',{staticClass:"margin-inputf-regular",attrs:{"textFloat":"","placeholder":_vm.changeName(profile),"type":_vm.checkFieldConditon(profile),"name":profile.profile_name,"className":"mb-2","isValidate":_vm.field.$error,"v":_vm.field},model:{value:(_vm.form[_vm.checkIsProfile(profile.field_profile_type_id)]),callback:function ($$v) {_vm.$set(_vm.form, _vm.checkIsProfile(profile.field_profile_type_id), $$v)},expression:"form[checkIsProfile(profile.field_profile_type_id)]"}}):_vm._e(),(profile.field_profile_type_id == 10)?_c('p',{staticClass:"text-right text-link mb-2",on:{"click":function($event){return _vm.addTel()}}},[_vm._v(" เบอร์โทรศัพท์สำรอง ")]):_vm._e()],1)],1):(profile.field_type_id == 16)?_c('InputSelect',{staticClass:"f-regular",attrs:{"title":"","text":profile.name,"name":profile.profile_name,"placeholder":profile.name,"valueField":"name","textField":"display_name","options":profile.field_choices
            ? profile.field_choices.filter((el) => el.is_display == 1)
            : [],"isRequired":profile.required,"v":_vm.field,"validateText":profile.validate_text,"isValidate":_vm.field.$error,"formType":""},on:{"change":(val) => _vm.changeLocale(val, true)},scopedSlots:_vm._u([{key:"option-first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- "+_vm._s(profile.name)+" --")])]},proxy:true}],null,true),model:{value:(_vm.form[_vm.checkIsProfile(profile.field_profile_type_id)]),callback:function ($$v) {_vm.$set(_vm.form, _vm.checkIsProfile(profile.field_profile_type_id), $$v)},expression:"form[checkIsProfile(profile.field_profile_type_id)]"}}):(
          profile.field_profile_type_id == 2 ||
          profile.field_profile_type_id == 21
        )?_c('b-row',[_c('b-col',[_vm._v(" "+_vm._s(profile.validate_text)+" "),_c('InputSelect',{attrs:{"title":"","placeholder":profile.name,"options":profile.field_choices.map((x) => ({
                text: x.display_name,
                value: x.name,
              })),"validateText":profile.validate_text,"isValidate":_vm.field.$error,"v":_vm.field},on:{"onDataChange":(val) =>
                _vm.handleSelected(
                  val,
                  _vm.checkIsProfile(profile.field_profile_type_id),
                  profile.field_choices
                )},scopedSlots:_vm._u([{key:"option-first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- "+_vm._s(profile.name)+" --")])]},proxy:true}],null,true),model:{value:(_vm.form[_vm.checkIsProfile(profile.field_profile_type_id)]),callback:function ($$v) {_vm.$set(_vm.form, _vm.checkIsProfile(profile.field_profile_type_id), $$v)},expression:"form[checkIsProfile(profile.field_profile_type_id)]"}})],1),(_vm.isOtherPrefix && profile.field_profile_type_id == 2)?_c('b-col',[_c('InputText',{attrs:{"className":"mb-2","textFloat":"","placeholder":profile.field_choices.find((x) => x.choice_type_id == 3)
                .description},model:{value:(_vm.otherPrefix),callback:function ($$v) {_vm.otherPrefix=$$v},expression:"otherPrefix"}})],1):(_vm.isOtherGender && profile.field_profile_type_id == 21)?_c('b-col',[_c('InputText',{attrs:{"className":"mb-2","textFloat":"","placeholder":profile.field_choices.find((x) => x.choice_type_id == 3)
                .description},model:{value:(_vm.otherGender),callback:function ($$v) {_vm.otherGender=$$v},expression:"otherGender"}})],1):_vm._e()],1):(profile.field_type_id == 7)?[_c('div',{staticClass:"datetime-container"},[_c('datetime',{ref:'data-picker' + profile.name,refInFor:true,class:[
              'date-picker-input',
              'date-filter',
              {
                'border-red': _vm.field.$error,
              },
            ],attrs:{"type":"date","placeholder":profile.name,"format":'dd/MM/yyyy',"value-zone":"Asia/Bangkok","maxDatetime":_vm.now,"validateText":_vm.field.validate_text,"isValidate":_vm.field.$error,"v":_vm.field,"isRequired":profile.required},on:{"input":(val) => _vm.form[_vm.checkIsProfile(profile.field_profile_type_id)] = (val),},model:{value:(_vm.form[_vm.checkIsProfile(profile.field_profile_type_id)]),callback:function ($$v) {_vm.$set(_vm.form, _vm.checkIsProfile(profile.field_profile_type_id), $$v)},expression:"form[checkIsProfile(profile.field_profile_type_id)]"}}),_c('div',{staticClass:"calendar-icon"},[_c('font-awesome-icon',{attrs:{"icon":"calendar-alt"}})],1)],1),(_vm.field.$error)?_c('span',{staticClass:"description text-error"},[_vm._v(" กรุณากรอกข้อมูล ")]):_vm._e()]:(profile.field_type_id == 8)?_c('div',[(_vm.changeName(profile))?_c('label',[_vm._v(" "+_vm._s(_vm.changeName(profile))+" "),(profile.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('InputUploadImage',{attrs:{"id":profile.id,"label":"","img":_vm.form[_vm.checkIsProfile(profile.field_profile_type_id)],"v":_vm.field,"validateText":_vm.field.validate_text,"isValidate":_vm.field.$error},on:{"setFileName":(val) => _vm.setImageName(val, profile.field_profile_type_id)},model:{value:(_vm.form[_vm.checkIsProfile(profile.field_profile_type_id)]),callback:function ($$v) {_vm.$set(_vm.form, _vm.checkIsProfile(profile.field_profile_type_id), $$v)},expression:"form[checkIsProfile(profile.field_profile_type_id)]"}}),_c('div',{staticClass:"f-xs color-gray mt-1"},[_vm._v(" ไฟล์ที่อัพโหลดต้องเป็นไฟล์นามสกุล PDF, PNG ,JPG ขนาดไม่เกิน 2.5 mb เท่านั้น ")]),(_vm.field.$error)?_c('span',{staticClass:"text-danger"},[_vm._v("กรุณาอัพโหลดไฟล์")]):_vm._e()],1):_vm._e()],2)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }